import ClientsRepository from "../repository/Clients.repository";

export default class Clients {
  static async getAll(userType) {
    return new Promise((resolve, reject) => {
      ClientsRepository.getAll(userType)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static async findById(id) {
    return new Promise((resolve, reject) => {
      ClientsRepository.findById(id)
        .then((res) => {
          console.log(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static async getAllForPurchase() {
    return new Promise((resolve, reject) => {
      ClientsRepository.getAllForPurchase()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static async create(client) {
    return new Promise((resolve, reject) => {
      ClientsRepository.create(client)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  static async edit(client) {
    return new Promise((resolve, reject) => {
      ClientsRepository.edit(client)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }


  static async delete(idClient) {
    return new Promise((resolve, reject) => {
      ClientsRepository.delete(idClient)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}
