<template>
  <v-container>
    <div class="white">
      <div class="px-9 py-5 d-flex align-center justify-space-between">
        <h1 class="primary--text text-h6 font-weight-black">{{ getTitle() }}</h1>
        <v-btn
          @click="$emit('changeComponent', 'DetailsAntenna', idAntenna)"
          color="primary"
          depressed
          outlined
          >REGRESAR</v-btn
        >
      </div>
      <v-divider></v-divider>
      <v-progress-linear v-if="isLoading" indeterminate />
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="px-9 py-5 d-flex align-center justify-space-between">
          <v-row>
            <v-col cols="12" v-if="type === 'new'">
              <p class="text-subtitle-2 font-weight-medium mb-2">Cliente</p>
              <v-autocomplete
                class="rounded-0"
                dense
                height="50"
                placeholder="Buscar cliente"
                outlined
                return-object
                hide-details
                :items="clients"
                item-text="name"
                @change="clientSelected"
              ></v-autocomplete>
            </v-col>
            <v-col
              class="text-uppercase font-weight-bold"
              cols="12"
              v-if="client !== null"
            >
              <p class="mb-2 font-weight-black">Cliente:</p>
              <p class="mb-0">{{ client.name }}</p>
              <p class="mb-0">{{ client.movilNumber }}</p>
              <p class="mb-0">{{ client.address }}</p>
            </v-col>

            <v-col cols="6">
              <p class="text-subtitle-2 font-weight-medium mb-2">IP</p>
              <v-text-field
                v-model="service.ip"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba la dirección IP del servicio"
                outlined
                :rules="required"
                :readonly="type === 'view'"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <p class="text-subtitle-2 font-weight-medium mb-2">Frecuencia</p>
              <v-text-field
                v-model.number="service.frequency"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba la frecuencia del servicio"
                outlined
                type="number"
                suffix="Ghz"
                :rules="required"
                :readonly="type === 'view'"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <p class="text-subtitle-2 font-weight-medium mb-2">Usuario</p>
              <v-text-field
                v-model="service.user"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba el usuario del servicio"
                outlined
                :rules="required"
                :readonly="type === 'view'"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <p class="text-subtitle-2 font-weight-medium mb-2">Password</p>
              <v-text-field
                v-model="service.password"
                class="rounded-0"
                dense
                height="50"
                placeholder="Escriba el password del servicio"
                outlined
                :rules="required"
                :readonly="type === 'view'"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-form>
      <div class="px-9 py-5 d-flex justify-end" v-if="type !== 'view'">
        <v-btn
          class="px-8 mb-7 font-weight-medium"
          @click="saveService"
          color="primary"
          depressed
          :disabled="client === null"
          :loading="isSaving"
          >{{ type === "new" ? "GUARDAR" : "ACTUALIZAR" }} SERVICIO</v-btn
        >
      </div>
    </div>
  </v-container>
</template>

<script>
// Domains
import Client from "../../domain/Clients";
import Service from "../../domain/Service";

export default {
  name: "ServiceAntenna",
  async mounted() {
    await this.getClients();
    this.isLoading = false
    if (this.type !== "new") {
      this.service.clientID = this.serviceView.client._id;
      this.service.ip = this.serviceView.ip;
      this.service.frequency = this.serviceView.frequency;
      this.service.password = this.serviceView.password;
      this.client = this.clients.find(
        (client) => client._id === this.serviceView.client._id
      );
    }
  },
  props: {
    idAntenna: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    serviceView: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    clients: [],
    client: null,
    service: {
      clientID: "",
      ip: "",
      frequency: "",
      user: "",
      password: "",
    },
    valid: true,
    required: [(v) => !!v || "Campo requerido"],
    isLoading: true,
    isSaving: false,
  }),
  methods: {
    async getClients() {
      try {
        this.clients = await Client.getAll();
      } catch (error) {
        console.warn(error);
      }
    },
    clientSelected(e) {
      this.service.clientID = e._id;
      this.client = e;
    },
    async saveService() {
      try {
        if (!this.$refs.form.validate()) return false;
        this.isSaving = true;
        this.service.antennaID = this.idAntenna;

        if (this.type === "edit") {
          this.updated();
          return true;
        }

        await Service.create(this.service);
        this.$emit("changeComponent", "DetailsAntenna", this.idAntenna);
      } catch (error) {
        console.warn(error);
      } finally {
        this.isSaving = false;
      }
    },
    async updated() {
      try {
        await Service.update(this.serviceView._id, this.service);
        this.$emit("changeComponent", "DetailsAntenna", this.idAntenna);
      } catch (error) {
        console.warn(error);
      }
    },
    getTitle() {
      if (this.type === "new") return `NUEVO SERVICIO`;
      if (this.type === "view") return `SERVICIO`;
      if (this.type === "edit") return `EDITAR SERVICIO`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>