import Service from "./config/ConfigRepositoryAuth";

export default {
  async getAll(userType) {
    console.log(`clients?userType=${userType}`);
    return await Service.get(`clients?userType=${userType}`);
  },

  async getAllForPurchase() {
    return await Service.get(`clients_params`);
  },

  async findById(id) {
    return await Service.get(`clients/${id}`);
  },

  async create(client) {
    return await Service.post(`clients`, client);
  },

  async edit(client) {
    return await Service.put(`clients/${client.id}`, client);
  },

  async delete(idClient) {
    return await Service.delete(`clients/${idClient}`);
  },
};
