import ServiceRepository from "../repository/Service.repository";

export default class Antenna {
  static async findByAntennaID(antennaID) {
    return new Promise((resolve, reject) => {
      ServiceRepository.findByAntennaID(antennaID)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static async create(service) {
    return new Promise((resolve, reject) => {
      ServiceRepository.create(service)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  static async update(serviceID, service) {
    return new Promise((resolve, reject) => {
      ServiceRepository.update(serviceID, service)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}
