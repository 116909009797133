import Service from "./config/ConfigRepositoryAuth";

export default {
  async create(service) {
    return await Service.post(`service`, service);
  },
  async findByAntennaID(antennaID) {
    return await Service.get(`service/${antennaID}`);
  },
  async update(serviceID, service) {
    return await Service.put(`service/${serviceID}`, service);
  },
};
